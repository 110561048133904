<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-icon medium color="blue" v-bind="attrs" v-on="on" @click="fetchData()">mdi-pencil</v-icon>
        </template>
        <v-card>
            <v-card-title class="justify-center">
                <span class="text-h3">{{ $t('Edit Meal') }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <app-image-input-file :srcImage="data.image ? getImgUrl(data.image) : ''"  :resetImage="resetImageInput" :createFeature="true"
                             :imgHeight="140" :imgWidth="125" :placeHolder="$t('image')" @fileValue="setMealPhoto($event)"
                                @fileRemoved="deleteMealPhoto"></app-image-input-file>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field :dark="$store.state.isDarkMode"  v-model="data.name" :label="$t('name')" :hint="$t('requird')"
                                required></v-text-field>
                                <v-text-field class="py-5" :dark="$store.state.isDarkMode" :label="$t('parcode')" dense
                                 v-model="data.barcode" ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-select :dark="$store.state.isDarkMode"  v-model="data.status" :items="items" :label="$t('status')"
                                :hint="$t('requird')"></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field :dark="$store.state.isDarkMode"  v-model="data.price" :label="$t('price')" type="number"
                             :hint="$t('requird')" required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-autocomplete :dark="$store.state.isDarkMode"  :label="$t('Group')" v-model="group_id" color="#757575"
                                item-text="name" item-value="id" :items="group_idA"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="py-4">
                            <AutoCompleteField v-if="returnAbility('invoice-template:index')" :label="$t('invoice type')" v-model="data.invoice_template_id" returnObject
                                endPoint="/inventories/invoice-template/auto-complete" />
                        </v-col>
                        <v-col cols="12">
                            <v-select :dark="$store.state.isDarkMode"  v-model="data.days" chips :items="days" :menu-props="{ maxHeight: '400' }"
                                :label="$t('dayes Avilable in')" :hint="$t('requird')" multiple></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field :dark="$store.state.isDarkMode"  v-model="data.description" :label="$t('description')"
                             :hint="$t('requird')" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="rest">
                    {{ $t('cancel') }}
                </v-btn>
                <v-btn :color="btnColor" text @click="editMeal">
                    <span v-if="!meal.editMealState.loading"> {{ $t('edit') }}</span>
                    <v-progress-circular v-else :width="3" color="blue" indeterminate></v-progress-circular>
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";
import imageInputFile from "../../../components/base/imageInputFile.vue";
import { meal } from '../../../store/auth'
import AutoCompleteField from "../components/core/AutoCompleteField.vue";

const mealState = () => {
    return {
        name: '',
        description: '',
        image: null,
        barcode: null,
        status: '',
        price: null,
        invoice_template_id: null,
        days: []
    }
}
export default {
    props: ['item'],
    components: {
        appImageInputFile: imageInputFile,
        AutoCompleteField,
    },
    data() {
        return {
            meal,
            group_id: null,
            group_idA: [],
            fromDb: undefined,
            data: mealState(),
            resetImageInput: false,
            imagePath: '',
            dialog: meal.editMealState.loading,
            items: [
                'available',
                'unavailable'
            ],
            days: [
                'sunday',
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday'
            ]
        }
    },
    methods: { 
        fetchData() {
            this.groupAc();
            this.group_id = this.data.group.id;
            console.log(this.data);
        },
        rest() {
            this.data = this.item
            this.dialog = false
        },
        // isValidImage() {
        //     return typeof this.data.image === 'string';
        // },
        setMealPhoto(file) {
            this.data.image = file;
        },
        deleteMealPhoto() {
            this.data.image = null;
        },
        getImgUrl(item) {
            let domain = "https://incentive-dev.com/"
            let item2 = item.replace("public", "storage")
            return domain + item2
        },
        async editMeal()  {
            this.data = {
                name: this.data.name,
                description: this.data.description,
                image: this.data.image,
                barcode: this.data.barcode,
                status: this.data.status,
                price: this.data.price,
                days: this.data.days,
                group_id: this.group_id,
                invoice_template_id: this.data.invoice_template_id?.id ?? null,
            };
            if (this.btnColor == 'blue') {
               try {
                if (this.data.image != null && !(typeof this.data.image === 'string')) {
                  await  meal.editMealImage(this.data.image, this.item.id)
                }
               await meal.editMeal(this.data, this.item.id)
                this.resetImageInput = true;

               } catch (error) {
                
               }
               this.$emit("Submitted");

                this.dialog = false; 
            } else {
                notify(
                    this.$t('please complete all info'),
                    { timeout: 4000, rtl: true },
                    "error"
                );
            }

        },
        async groupAc() {
            try {
                const searchResult = await axios.get(
                    "/meal-group"
                );
                this.group_idA = searchResult.data.data;
            } catch (err) {
                console.log("err", err);
            }
        },
    },
    computed: {
        btnColor() {
            if (this.data.description == '' | this.data.name == '' | this.data.price == null | this.data.barcode == null ) {
                return 'gray'
            } else {
                return 'blue'
            }
        },
    },
    mounted() {
        this.data = Object.assign({}, this.item);
        this.group_id = this.data.group.id;
        this.data.invoice_template_id = this.item.invoice_template;
        
        // this.data.image = null
    },
    created() {
        
    },
}
</script>
