import axios from 'axios'
const state = {
    loading: false,
    currencies: [],
    currency: {
        id: null,
        exchange_updatable: 1,
        exchange_records: {  
            tableHeaders: [],
            tableData: [],
            accessTableData: [],
            loading: false,
            tableMeta: {
                total: 10,
                page: 1,
                perpage: 1000,
            },
        },
        information: {
          name: '',
          code: {
            desc: "Code",
            value: "",
          },
          exchange: {
            desc: "Exchange",
            value: null,
          },
        }
    },
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
};
const getters = {
    getLoadingStatus(state) {
        return state.loading;
    },
    getCurrency(state) {
        return state.currency
    },
    getCurrencies(state) {
        return state.currencies
    },
    getTableOptions(state) {
        return state.tableOptions
    },
};

const mutations = {
    setLoadingStatus(state, status) {
        state.loading = status;
    },
    setCurrency(state, currency) {
        state.currency.id = currency.id;
        state.currency.exchange_updatable = currency.exchange_updatable;
        state.currency.information.name = currency.name;
        state.currency.information.code.value = currency.code;
        state.currency.information.exchange.value = currency.exchange;
        state.currency.exchange_records.tableHeaders = ["date","Exchange","settings",];
        state.currency.exchange_records.tableData = currency.exchange_records;
        state.currency.exchange_records.accessTableData = [["date"],["exchange"],];
        
    },
    setCurrencies(state, currencies) {
        state.currencies = currencies
    },
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    },
};

const actions = {
    async fetchCurrencies({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {};
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key];
                }
            }
            if(!queryParams.paginated) params.paginated = queryParams.paginated;
            const response = await axios.get("/accounting/coin", {
                params,
            });
            commit('setCurrencies', response.data.data)
            commit('setTableData', {
                tableHeaders: [
                    "name",
                    "Code",
                    "Exchange",
                    "settings",
                ],
                tableData: response.data.data,
                accessTableData: [
                    ["name"],
                    ["code"],
                    ["exchange"],
                ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    async fetchCurrency({ commit }, id) {
        try {
            commit('setLoadingStatus', true)
            const response = await axios.get(`/accounting/coin/${id}`);
            commit('setCurrency', response.data.data);
            state.currency.information.exchange_records.tableMeta = {
                total: response.data.meta.total,
                page: response.data.meta.current_page,
                perpage: response.data.meta.per_page,
            };
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoadingStatus', false)
        }
    },
    
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}